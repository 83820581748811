import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    
    
@Injectable({    
  providedIn: 'root'    
})    
export class UpdateLogoService {    
    
  invokeNavComponentFunction = new EventEmitter();    
  subsVar: Subscription;    
    
  constructor() { }    
    
  callGetLogoNav() {        
    this.invokeNavComponentFunction.emit();    
  }    
}  