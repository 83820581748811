import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments';
import { User, Role } from '../models';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  // end points
  private azureAuthorize = environment.apiUrl + '/auth/login';
  private azureTwoFactorAuth = environment.apiUrl + '/auth/validate-2fa';
  private azureResend2fa = environment.apiUrl + '/auth/resend-2fa';
  private azureAuthVerify = environment.apiUrl + '/auth/verify';

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public login(email: string, password: string): Observable<any> {
    return this._http
      .post<any>(this.azureAuthorize, {
        email,
        password,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public resendCode(token: string): Observable<any> {
    return this._http
      .post<any>(this.azureResend2fa, {
        access_token: token,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  public twoFactorAuth(token: string, code: string): Observable<any> {
    return this._http
      .post<any>(this.azureTwoFactorAuth, {
        token: token,
        token_number: code,
      })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                'Has ingresado exitosamente ' + user.first_name,
                '👋 Bienvenido, ' + user.first_name + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(user);
          }
          return user;
        })
      );
  }

  public validatorAuth(): Observable<any> {
    const headers = {
      Authorization: `Bearer ${this.currentUserSubject.value.token}`,
    };
    return this._http
      .get(this.azureAuthVerify, { headers: headers })
      .pipe(map((response) => response));
  }

  public logout(): void {
    // remove user from local storage to log user out
    localStorage.clear();
    // notify
    this.currentUserSubject.next(null);
  }
}
