import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HeadersService } from './headers.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  //endpoints
  public upload = environment.apiUrl + '/logo/upload';
  public get = environment.apiUrl + '/logo/get-file';
  public getDays = environment.apiUrl + '/users/get';
  public updateDays = environment.apiUrl + '/users/update';


  constructor(
    private _http: HttpClient,
    private headers: HeadersService,
  ) {  }

  public updateDaysUser(body): Observable<any> {
    return this._http.post(this.updateDays, body, this.headers.getHeader())
    .pipe(
      map((response) => response)
    );
  }

  public getDaysUser(): Observable<any> {
    return this._http.get(this.getDays, this.headers.getHeader())
    .pipe(
      map((response) => response)
    );
  }
  

  public uploadLogo(body): Observable<any> {
    return this._http.post(this.upload, body, this.headers.getHeader())
    .pipe(
      map((response) => response)
    );
  }

  public getLogo(body):Observable<any>{
    return this._http.post(this.get, body, {...this.headers.getHeader(), responseType: 'blob' as 'json'})
    .pipe(
      map((response) => response)
    );
  }

}
