import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionResponse } from '@models/profile/session.model';

@Injectable({
  providedIn: 'root'
})
export class HeadersService {

  public currentUser: SessionResponse;

  constructor() { }

  getHeader(){
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const token =  sessionStorage.getItem('tokenAzure');
    return   {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
        'Authorization': `Bearer ${this.currentUser.token}`
      })
    }
  }
}
