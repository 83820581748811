import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'asignation',
    title: 'Asignación',
    type: 'item',
    icon: 'users',
    url: 'pages/asignation'
  },
  {
    id: 'projects',
    title: 'Proyectos',
    type: 'item',
    icon: 'folder-plus',
    url: 'pages/projects'
  },
  {
    id: 'vulnerabilities',
    title: 'Vulnerabilidades',
    type: 'item',
    icon: 'alert-triangle',
    url: 'pages/vulnerabilities'
  },
  {
    id: 'evcs',
    title: 'EVC',
    type: 'item',
    icon: 'aperture',
    url: 'pages/evcs'
  },
  {
    id: 'vectorsLibrary',
    title: 'Vectores',
    type: 'collapsible',
    icon: 'trello',
    badge: {
      title: '3',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'vectors',
        title: 'Gestionar vectores',
        type: 'item',
        icon: 'circle',
        url: 'pages/vectors'
      },
      {
        id: 'groupings',
        title: 'Agrupaciones',
        type: 'item',
        icon: 'circle',
        url: 'pages/groupings'
      },
      {
        id: 'environments',
        title: 'Entornos',
        type: 'item',
        icon: 'circle',
        url: 'pages/environments'
      }
    ]
  },
  {
    id: 'reports',
    title: 'Informes',
    type: 'collapsible',
    icon: 'file-plus',
    badge: {
      title: '1',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'vector-report',
        title: 'Informe ejecución',
        type: 'item',
        icon: 'circle',
        url: 'pages/reports/vector-execution'
      },
    ]
  },
  {
    id: 'domains',
    title: 'Dominios',
    type: 'item',
    icon: 'list',
    url: 'pages/domains'
  },
  {
    id: 'findings',
    title: 'Hallazgos',
    type: 'item',
    icon: 'archive',
    url: 'pages/findings'
  },
  {
    id: 'recommend',
    title: 'Recomendaciones',
    type: 'item',
    icon: 'inbox',
    url: 'pages/recommend'
  },
  {
    id: 'policy',
    title: 'Políticas',
    type: 'item',
    icon: 'clipboard',
    url: 'pages/policy'
  },
  {
    id: 'holiday',
    title: 'Días festivos',
    type: 'item',
    icon: 'crosshair',
    url: 'pages/holiday'
  },
  {
    id: 'Estadísticas',
    title: 'Estadísticas',
    type: 'item',
    icon: 'divide-square',
    url: 'pages/statistics'
  },
  {
    id: 'configuraciones',
    title: 'Configuraciones',
    type: 'collapsible',
    icon: 'settings',
    badge: {
      title: '2',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'personalization_settings',
        title: 'Personalización',
        type: 'item',
        icon: 'circle',
        url: 'pages/personalization-settings'
      },
      {
        id: 'user_settings',
        title: 'Usuarios',
        type: 'item',
        icon: 'circle',
        url: 'pages/user-settings'
      },
    ]
  },
]
